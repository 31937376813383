import React from "react"
import { graphql } from 'gatsby';

import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'


export default ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="費用"
      pagedesc="採用サイト制作費用についてご説明いたします。"
      pagepath={location.pathname}
    />
    <main className="l-content">
      <div className="p-content">
        <div className="m-mv mv">
          <div className="mv__img-wrap">
            <Img fluid={data.key01.childImageSharp.fluid} alt="メインビジュアル画像" style={{ height: "100%", width: "100%" }} />
            <h2 className="mv__hdg">費用</h2>
          </div>
        </div>
        <div className="p-head head l-wrap">
          <h3 className="head__hdg--01">price</h3>
          <h4 className="head__desc--01">費用</h4>
        </div>

        <div className="p-price price">
          <div className="price__inner l-wrap">
            <div className="price__item">
              <h3 className="price__hdg">払いきりプランとは</h3>
              <div className="price__desc">
                <p className="text">ご契約時に、制作費の半額をお支払いいただきウェブサイト完成時に残りの半額をお支払いいただく契約です。</p>
              </div>
            </div>

            <div className="price__item">
              <h3 className="price__hdg">月々払いプランとは</h3>
              <div className="price__desc">
                <p className="text">初期制作費（払いきりの1/4程度）を制作前にお支払いいただき、残りの金額を１年（12ヶ月）かけてお支払いいただくプランです。</p>
                <ul className="price__list">
                  <li className="price__section item">
                    <h4 className="item__hdg">
                      <span className="item__icon">
                      <FontAwesomeIcon icon={faCheck} />
                      </span>
                      途中での解約も可能
                    </h4>
                    <p className="item__pra">「成果が出ない」「この先も効果が出るとは思えない」場合は契約途中でも解約が可能です。</p>
                  </li>

                  <li className="price__section item">
                    <h4 className="item__hdg">
                      <span className="item__icon">
                      <FontAwesomeIcon icon={faCheck} />
                      </span>
                      アクセス解析サービスの割安利用
                    </h4>
                    <p className="item__pra">月初に、ウェブサイトへのアクセスを解析したレポートと改善提案をお送りします。</p>
                  </li>

                  <li className="price__section item">
                    <h4 className="item__hdg">
                      <span className="item__icon">
                      <FontAwesomeIcon icon={faCheck} />
                      </span>
                      ウェブサイト保守オプション
                    </h4>
                    <p className="item__pra">ウェブサイト保守を割安でお受けいたします。</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>{/* p-content */}
    </main>
  </Layout>
)

export const query = graphql`
  query {
    key01: file(relativePath: {eq: "solutions_mv01.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`